/**
 * Checks if the given event is a KeyboardEvent.
 *
 * This function acts as a type guard, allowing TypeScript to narrow the type
 * of the event to KeyboardEvent if the function returns true.
 *
 * @param event - The event to check (e.g., a KeyboardEvent, MouseEvent, etc.).
 * @returns - True if the event is a KeyboardEvent, false otherwise.
 */
export default function isKeyboardEvent(event: Event): event is KeyboardEvent {
	return event instanceof KeyboardEvent;
}
