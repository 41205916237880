<template>
	<div
		class="popover-menu"
		ref="menu-root"
	>
		<a
			class="trigger"
			:href="triggerLink"
			@click.prevent="toggleMenu"
			@keydown.esc="toggleMenu"
		>
			<slot name="title"></slot>
			<slot name="icon"></slot>
			<slot name="trigger"></slot>
		</a>
		<Transition name="slide-fade">
			<div
				v-if="open"
				:id="id"
				class="menu"
				:class="{ dark: dark }"
				:style="style"
				@keydown.esc="toggleMenu"
			>
				<div
					class="hidden-desktop"
					style="display: flex; justify-content: flex-end; cursor: pointer"
				>
					<TnIcon
						name="close"
						@click="toggleMenu"
					/>
				</div>
				<slot
					name="menu"
					:open="open"
				></slot>
			</div>
		</Transition>
	</div>
</template>
<script setup lang="ts">
// TODO Tabtrapping
// TODO schema updates in Gizmo

import telenorid from "~/telenorid/telenorid.js";

interface Props {
	disabled?: boolean;
	triggerLink?: string;
	menuHidden?: boolean;
	minWidth?: number;
	dark?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	triggerLink: "javascript:",
	menuHidden: false,
	minWidth: 218,
	dark: false,
});

const id = useId();
const open = ref(false);

const { handleTabTrapping, previouslyActiveElement } = useTabTrapping(id);

const menuRoot = useTemplateRef<HTMLElement>("menu-root");

const style = computed(() => {
	return `min-width: ${props.minWidth}px`;
});

watch(
	() => props.menuHidden,
	(newState) => {
		if (open.value && !newState) open.value = false;
	},
);

watch(
	() => useRoute(),
	() => {
		closeMenu();
		document.removeEventListener("click", closeClickOutside);
	},
);

const toggleMenu = (event: Event) => {
	if (props.disabled) {
		if (props.triggerLink === "/login") telenorid.signinRedirect();
		else useRouter().push(props.triggerLink);
		return;
	}
	if (isKeyboardEvent(event)) {
		if (event.key === "Escape") {
			open.value = false;
			previouslyActiveElement.value?.focus();
		}
	} else {
		open.value = !open.value;
	}
	handleTabTrapping(open.value);
	if (open.value) {
		setTimeout(() => {
			document.addEventListener("click", closeClickOutside);
		}, 200); // wait for animation to finish
	} else {
		document.removeEventListener("click", closeClickOutside);
	}
};

const closeClickOutside = (e: MouseEvent) => {
	e.stopPropagation();
	if (!menuRoot.value?.contains(e.target as Node)) {
		toggleMenu(e);
	}
};

const closeMenu = () => {
	open.value = false;
};

// Expose method to parent components
defineExpose({
	toggleMenu,
});

onBeforeRouteLeave(() => {
	closeMenu();
});
</script>
<style lang="scss" scoped>
.trigger {
	display: flex;
	align-items: center;
	color: inherit;
	text-decoration: none;

	span {
		margin-right: $spacing-s;

		@include breakpoint(mobile) {
			display: none;
		}
	}
}

.popover-menu {
	position: relative;
}

.menu {
	position: absolute;
	right: 0;
	top: 100%;
	margin-top: $spacing-m;
	background: #fff;
	border-radius: 7px;
	z-index: 100;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
	width: auto;
	padding: $spacing-l;
	max-height: 95vh;
	overflow-y: scroll;

	&.dark {
		background: $color-primary-dark;
		box-shadow: 0 0 10px 0 rgba($color-primary-dark, 0.1);
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
	-ms-overflow-style: none;

	/* IE and Edge */
	scrollbar-width: none;

	/* Firefox */
}

.slide-fade-enter-active {
	transition: all 0.2s ease;
}

.slide-fade-leave-active {
	transition: all 0.2s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
	transform: translateY(-5px);
	opacity: 0;
}

@include breakpoint(mobile) {
	.menu {
		position: fixed;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: $spacing-s;
		width: 100vw !important;
		max-width: 100vw !important;
		top: auto;
		right: 0;
		left: 0;
		min-width: initial !important;
	}
}
</style>
