export function useTabTrapping(element: string) {
	const elementInFocus = ref<number>(NaN);
	const previouslyActiveElement = ref<HTMLElement | null>(null);

	const handleTabTrapping = (isOpen: boolean) => {
		if (isOpen) {
			previouslyActiveElement.value = document.activeElement as HTMLElement;
			document.addEventListener("keydown", tabTrapping);
		} else {
			document.removeEventListener("keydown", tabTrapping);
			elementInFocus.value = NaN;
		}
	};

	const tabTrapping = (event: KeyboardEvent) => {
		if (event.key === "Tab" || event.code === "Tab") {
			const parentNode: HTMLElement = document.getElementById(element);
			if (parentNode) {
				event.stopPropagation();
				event.preventDefault();
				const focusable = parentNode.querySelectorAll(
					'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls], summary, [tabindex^="0"], [tabindex^="1"], [tabindex^="2"], [tabindex^="3"], [tabindex^="4"], [tabindex^="5"], [tabindex^="6"], [tabindex^="7"], [tabindex^="8"], [tabindex^="9"]',
				) as NodeListOf<HTMLElement>;
				if (!isNaN(elementInFocus.value)) {
					if (event.shiftKey) {
						elementInFocus.value = elementInFocus.value === 0 ? focusable.length - 1 : elementInFocus.value - 1;
					} else {
						elementInFocus.value = elementInFocus.value >= focusable.length - 1 ? 0 : elementInFocus.value + 1;
					}
				} else {
					elementInFocus.value = 0;
				}
				focusable[elementInFocus.value].focus();
			}
		}
	};

	return { previouslyActiveElement, handleTabTrapping };
}
